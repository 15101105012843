<template>
	<div class="employee-table">
		<v-data-table
			class="overflow-x elevation-1"
			height="100%"
			item-key="_id"
			hide-default-footer
			ref="table"
			:headers="headers"
			:items="items"
			:loading="loading"
			:page.sync="options.page"
			:options="options"
			:server-items-length="options.itemsLength"
			@click:row="handleRowClick"
		>
			<!--  ХЭДЕР ТАБЛИЦЫ -->
			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					class="justify-end pa-3 border-bottom"
					:pagination="pagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text=""
					:items-per-page-options="[]"
					disable-items-per-page
					:disable-pagination="loading"
					:show-first-last-page="true"
				>
					<!-- <template v-slot:page-text>
						{{ options.page || "-" }} из {{ options.pageCount || "-" }}
					</template> -->
					<template v-slot:prepend>
						<div class="d-flex flex-sm-grow-1 align-center justify-start">
							<!-- Кнопка создания -->
							<v-btn v-show="base_role == 'ksk' || base_role == 'ksk_manager'"
								class="mr-5"
								color="primary"
								@click="showModalCreate = true"
								small
								depressed
							>
								<v-icon class="mr-1" small>mdi-plus</v-icon>
								<span class="body-2 font-weight-light">Создать</span>
							</v-btn>
							<!-- Дата создания -->
							<div class="d-flex align-center mr-3">
								<span class="subtitle-2 grey--text mr-3">от</span>
								<DatePickerPlain
									:date.sync="filter.created_at_first"
									@change="updateQuery"
								/>
								<span class="mx-3 subtitle-2 grey--text">до</span>
								<DatePickerPlain
									:date.sync="filter.created_at_second"
									@change="updateQuery"
								/>
							</div>
							<v-menu
								offset-y
								max-width="420"
								:close-on-content-click="false"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn class="no-uppercase mr-5 ml-3 text-lowercase" small outlined v-bind="attrs" v-on="on" color="primary">
										<span class="body-2 font-weight-light no-uppercase">Фильтр &nbsp;</span>
										<v-icon size="20" style="-webkit-text-stroke: 0.15px white;">mdi-filter-outline</v-icon>
									</v-btn>
								</template>
								<v-card class="mt-3">
									<v-list class="pa-4">
										<v-row>
											<v-col sm="12">
												<span class="subtitle-2 font-weight-light grey--text">Поиск по статусу</span>
												<v-select
													v-model="filter.owner"
													class="border-all"
													placeholder="Поиск по статусу"
													:menu-props="{ offsetY: true }"
													solo
													dense
													flat
													hide-details
													item-text="name"
													item-value="id"
													:items="[
														{ name: 'Все', id: null },
														...catalog.apartments_affiliations,
													]"
													@change="updateQuery"
												>
												</v-select>
											</v-col>
											<v-col sm="12">
												<span class="subtitle-2 font-weight-light grey--text">Подтверждение</span>
												<v-select
													v-model="filter.approwed"
													class="border-all"
													placeholder="Подтверждение"
													:menu-props="{ offsetY: true }"
													solo
													dense
													flat
													hide-details
													:items="kskConfirmTypes"
													@change="updateQuery"
												>
												</v-select>
											</v-col>
											<v-col sm="12">
												<span class="subtitle-2 font-weight-light grey--text">Кем зарегистрирован</span>
												<v-select
													v-model="filter.registration"
													class="border-all"
													placeholder="Кем зарегистрирован"
													:menu-props="{ offsetY: true }"
													solo
													dense
													flat
													hide-details
													item-text="name"
													item-value="id"
													:items="[
														{ name: 'Все', id: null },
														{ name: 'Самостоятельно', id: 'self' },
														{ name: 'Менеджером', id: 'by_manager' },
														{ name: 'Диспетчером', id: 'by_dispatcher' },
													]"
													@change="updateQuery"
												>
												</v-select>
											</v-col>
											<v-col sm="12">
												<span class="subtitle-2 font-weight-light grey--text">ФИО пользователя</span>
												<v-text-field
													v-model="filter.fullname"
													placeholder="ФИО пользователя"
													class="border-all"
													solo
													dense
													flat
													hide-details
													@change="updateQuery"
												>
												</v-text-field>
											</v-col>
										</v-row>
									</v-list>
								</v-card>
							</v-menu>
							<!-- Сброс фильтров -->
							<v-btn
								class="mr-5 text-lowercase"
								color="primary"
								small
								outlined
								@click="resetFilters"
							>
								<span class="body-2 font-weight-light">
									<span class="text-capitalize">Сбросить</span> фильтры
								</span>
							</v-btn>
							<!-- <v-btn
								class="mr-5"
								color="primary"
								small
								depressed
								:loading="isExportingExcel"
								@click="exportExcel"
							>
								<v-icon class="mr-1" small>mdi-microsoft-excel</v-icon>
								<span class="body-2 font-weight-light text-capitalize">
									Экспорт
								</span>
							</v-btn> -->
						</div>
					</template>
				</v-data-footer>
			</template>
			<!-- ФИЛЬТРЫ ТАБЛИЦЫ -->
			<template v-slot:[`body.prepend`]>
				<tr>
					<!-- fio -->
					<td>
						<v-text-field
							v-model="filter.phone"
							:placeholder="phoneCode+'-'"
							v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
							class="border-all"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- apartment_address -->
					<td>
						<v-autocomplete
							v-model="filter.apartment_address"
							class="border-all"
							placeholder="Адрес"
							solo
							dense
							flat
							hide-details
							clearable
							item-text="apartment_address"
							item-value="id"
							:items="houses"
							@change="updateQuery"
						>
						</v-autocomplete>
					</td>
					<!-- type_id -->
					<td>
						<v-select
							v-model="filter.type_id"
							class="border-all"
							placeholder="Тип помещения"
							:menu-props="{ offsetY: true }"
							solo
							dense
							flat
							hide-details
							item-text="name"
							item-value="id"
							:items="[{ name: 'Все', id: null }, ...catalog.room_types]"
							@change="updateQuery"
						>
						</v-select>
					</td>
					<!-- apartment_number -->
					<td>
						<v-text-field
							v-model="filter.apartment_number"
							class="border-all"
							placeholder="№ Помещения"
							solo
							dense
							flat
							hide-details
							@change="updateQuery"
						>
						</v-text-field>
					</td>
					<!-- created_at -->
					<td>
						<DatePickerPlain
							:date.sync="filter.created_at_first"
							:in-table="true"
							@change="updateQuery"
						/>
					</td>
					<!-- last_entrance -->
					<!-- <td>
						<DatePickerPlain
							:date.sync="filter.last_entrance"
							:in-table="true"
							@change="updateQuery"
						/>
					</td> -->
					<!-- registration_type -->
					<!-- <td>
						<v-select
							v-model="filter.registration_type"
							class="border-all"
							solo
							dense
							flat
							hide-details
							:items="[
								{ text: 'Все', value: null },
								{ text: 'Диспетчер', value: 'dispatcher' },
								{ text: 'Приложение', value: 'app' },
							]"
							@change="updateQuery"
						>
						</v-select>
					</td> -->
				</tr>
			</template>
			<!-- ПОЛЯ ТАБЛИЦЫ -->
			<!-- <template v-slot:[`item.phone`]="{ item }">
				<td>
					{{ item.phone }}
				</td>
			</template> -->
			<template v-slot:[`item.apartment_address`]="{ item }">
				<td>
					{{ item.house ? item.house.apartment_address : "---" }}
					<p>Всего жителей: {{ item.owners.length }}</p>
				</td>
			</template>
			<template v-slot:[`item.owner`]="{ item }">
				<td>
					<template v-if="item.owners.length < 2">
						<p>{{ getFullname(item.owners[0].user)}}</p>
						<p>{{item.owners[0].user ? item.owners[0].user.phone : '---'}}</p>
						<p>{{item.owners[0].affilation.name || '---'}}</p>
						<p>
							<span v-if="item.owners[0].approwed">
								Подтвержден
								<img class="ml-2" src="@/assets/done.svg" />
								
							</span>
							<span v-else>
								Не подтвержден
								<img class="ml-2" src="@/assets/unconfirmed.svg" />		
							</span>
						</p>
					</template>
					<template v-else>
						<div>
							<v-menu bottom open-on-hover v-for="(owner, index) in item.owners" :key="index">
								<template v-slot:activator="{ on, attrs }">
									<p class="mt-0 mt-2">
										<v-btn text class="thighlighted-text no-uppercase pa-0" v-on="on" v-bind="attrs" style="border: 1px solid lightgrey;">{{ owner.user ? owner.user.phone : '---' }} 	&#9207;</v-btn>
									</p>
								</template>
								<v-card class="tooltip-card">
									<div v-if="owner.user">
										<p>{{ owner.user.phone }}</p>
										<p>{{ getFullname(owner.user) }}</p>
										<p>{{ owner.affilation.name }}</p>
										<p>
											<span v-if="owner.approwed">
												Подтвержден
												<img class="ml-2" src="@/assets/done.svg" />
											</span>
											<span v-else>
												Не подтвержден
												<img class="ml-2" src="@/assets/unconfirmed.svg" />
											</span>
										</p>
									</div>
								</v-card>
							</v-menu>
						</div>
					</template>
				</td>
			</template>
			<template v-slot:[`item.apartment_number`]="{ item }">
				<td>
					{{ item.apartment_number || "---" }}
				</td>
			</template>
			<template v-slot:[`item.type_id`]="{ item }">
				<td>
					{{ item.type ? item.type.name : "---" }}
				</td>
			</template>
			<!-- <template v-slot:[`item.fullname`]="{ item }">
				<td>
					{{ item | fullname }}
				</td>
			</template> -->
			<!-- <template v-slot:[`item.last_entrance`]="{ item }">
				<td>
					<span v-if="item.last_entrance">
						{{ item.last_entrance | timestamp(true) }}
					</span>
					<span v-else>---</span>
				</td>
			</template> -->
			<template v-slot:[`item.created_at`]="{ item }">
				<td>
					{{ item.owners[0].created_at | timestamp(true) }}
				</td>
			</template>
			<!-- <template v-slot:[`item.registration_type`]="{ item }">
				<td>
					{{ item.registration_self ? "Приложение" : "Диспетчер" }}
				</td>
			</template> -->
			
			<!-- <template v-slot:[`item.ksk_confirm`]="{ item }">
				<td>
					<img v-if="checkConfirm(item)" class="ml-2" src="@/assets/done.svg" />
					<img v-else class="ml-2" src="@/assets/unconfirmed.svg" /> -->
					<!-- <v-btn
						v-if="!item.apartments[0].ksk_confirm"
						class="subtitle-2 text-capitalize"
						color="primary"
						small
						depressed
						:loading="isConfirming === item._id"
						@click.stop="toggleTenantRegistration(item)"
					>
						
					</v-btn>
					<v-tooltip v-else bottom>
						<template v-slot:activator="{ on, attrs }">
							<span
								class="subtitle-2 font-weight-light"
								v-bind="attrs"
								v-on="on"
								@click.stop="toggleTenantRegistration(item)"
							>
								Подтвержден
							</span>
						</template>
						<span>Нажмите для отмены</span>
					</v-tooltip> -->
				<!-- </td>
			</template> -->
		</v-data-table>
		<!-- ОКНО СОЗДАНИЯ ЗАЯВКИ -->
		<TenantsAddModal
			v-if="showModalCreate"
			@close-modal="showModalCreate = false"
			@update-info="updateQuery"
		/>
		<notification-modal @close-modal="updateData"></notification-modal>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import { timestampToDdmmyyyy, getFullname } from "@/helpers/helpers";
import TenantsAddModal from "@/components/TenantsAddModal";
import DatePickerPlain from "@/components/app/DatePickerPlain";
import NotificationModal from "@/components/app/NotificationsModal";

export default {
	name: "TenantsTable",
	components: {
		DatePickerPlain,
		TenantsAddModal,
		NotificationModal
	},
	data() {
		return {
			items: [],
			filter: {
				apartment_address: this.$route.query.apartment_address || "",
				apartment_number: this.$route.query.apartment_number || "",
				created_at: this.$route.query.created_at || "",
				created_at_first:
					this.$route.query.created_at_first || "",
				created_at_second:
					this.$route.query.created_at_second || "",
				fullname: this.$route.query.fio || "",
				approwed: this.$route.query.approwed || "",
				last_entrance: this.$route.query.last_entrance || "",
				owner: this.$route.query.affilation_id || "",
				phone: this.$route.query.phone || "",
				registration: this.$route.query.registration || "",
				type_id: this.$route.query.type_id || "",
			},
			filtersQuery: {
				"apartment_address": "apartment_address",
				"apartment_number": "apartment_number",
				"created_at": "created_at",
				"created_at_first": "created_at_first",
				"created_at_second": "created_at_second",
				"fullname": "fio",
				"approwed": "approwed",
				"last_entrance": "last_entrance",
				"owner": "affilation_id",
				"phone": "phone",
				"registration": "registration",
				"type_id": "type_id",
			},
			headers: [
				// {
				// 	text: "Подтверждение жителя",
				// 	value: "ksk_confirm",
				// 	width: "40px",
				// 	sortable: false,
				// },
				// { text: "Телефон", value: "phone", width: "200px", sortable: false },

				// { text: "ФИО", value: "fullname", width: "250px", sortable: false },
				// { text: "Статус", value: "owner", width: "200px", sortable: false },
				{ text: "Пользователь", value: "owner", width: "300px", sortable: false },
				{
					text: "Адрес",
					value: "apartment_address",
					width: "250px",
					sortable: false,
				},
				{
					text: "Тип помещения",
					value: "type_id",
					width: "200px",
					sortable: false,
				},
				{
					text: "№ Помещения",
					value: "apartment_number",
					width: "150px",
					sortable: false,
				},
				{
					text: "Дата регистрации",
					value: "created_at",
					width: "200px",
					sortable: false,
				},
				// {
				// 	text: "Последний вход",
				// 	value: "last_entrance",
				// 	width: "200px",
				// 	sortable: false,
				// },
				// {
				// 	text: "Способ регистрации",
				// 	value: "registration_type",
				// 	width: "150px",
				// 	sortable: false,
				// },
			],
			options: {
				page: +this.$route.query.page || 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageCount: 0,
				itemsLength: 0,
			},
			kskConfirmTypes: [
				{ text: "Все", value: null },
				{ text: "Не подтвержденные", value: "false" },
				{ text: "Подтвержденные", value: "true" },
			],
			isConfirming: false,
			isExportingExcel: false,
			loading: true,
			showModalCreate: false,
			firstFetch: false,
		};
	},

	watch: {
		options: {
			handler() {
				if(this.firstFetch) {
					const query = { ...this.$route.query, page: this.options.page };
					this.$router.replace({ query });
					this.loadData(query);
				}
			},
			deep: true,
		},
	},

	computed: {
		...mapGetters({
			kskId: "auth/GET_KSK_ID",
			catalog: "catalog/GET_CATALOG",
			houses: "ksk/GET_HOUSES",
			houses_addresses: "ksk/GET_HOUSES_ADDRESSES",
			role: "auth/GET_USER_ROLE_VUEX",
			base_role: "auth/GET_USER_ROLE",
			phoneCode: "auth/GET_USER_PHONE_CODE",
		}),

	},

	methods: {
		updateData() {
			const query = { ...this.$route.query, page: this.options.page };
			this.loadData(query);
		},
		checkConfirm(item) {
			// for(let i = 0; i < item.ksk_houses.length; i++) {
			// 	if(item.main_apartment[i].ksk_confirm) {
			// 		return true
			// 	} else {
			// 		return false
			// 	}
			// }
			if(item.main_apartment?.owner.approwed) {
				return true
			}
			return false
		},
		getTypeId(type_id) {
			const res = this.catalog.room_types.find(e => e.id === type_id);
			return res?.name || "---";
		},

		// * Подтверждение пользователя
		async toggleTenantRegistration(item) {
			console.log(item);
			try {
				this.isConfirming = item.id;
				const res = await this.$api.ksk.confirm_tenant(
					item.apartments[0].owner_id
				);
				console.log(res);
				item.apartments[0].ksk_confirm = !item.apartments[0].ksk_confirm;
			} catch (error) {
				console.error(error);
			} finally {
				this.isConfirming = false;
			}
		},

		// ? Начало методов таблица
		handleRowClick(e) {
			// TODO: сделать поиск пользователя по _id
			if (window.getSelection().toString()) {
				return;
			}
			this.$router.push({
				path: `/tenants/${e.id}`,
				query: {
					...this.$route.query
				}
			});
		},

		async exportExcel() {
			this.isExportingExcel = true;
			try {
				const query = this.updateQuery(true);
				const res = await this.$api.ksk.export_tenants_list(query);
				const link = document.createElement("a");
				link.href = res;
				link.download = `Жители ${timestampToDdmmyyyy()}.xlsx`;
				link.click();
				console.log('query ex', query)
				console.log('res ex', res)
				console.log('link ex', link)
			} catch (error) {
				console.log(error);
			} finally {
				this.isExportingExcel = false;
			}
		},

		async resetFilters() {
			for (const key in this.filter) {
				this.filter[key] = "";
			}
			this.$router.replace({ query: null });
			await this.loadData({});
		},

		async loadData(params) {
			this.loading = true;
			console.log('paras', params)
			if(this.base_role !== 'administrator') {
				if(!params.apartment_address) {
					const kskHouseIds = this.houses.map(house => house.id);
					if(!kskHouseIds.length) {
						params.ksk_ids = [this.kskId]
					} else {
						params.houses_ids = kskHouseIds;
					}
				} else {
					params.houses_ids = [params.apartment_address]
					delete params['apartment_address'];
				}
			}	else {
				params.houses_ids = [params.apartment_address]
				delete params['apartment_address'];
			}

  		
			return this.$api.ksk
				.load_tenants({ ...params })
				.then(res => {
					this.options.page = res.currentPage;
					this.options.pageCount = res.pages;
					this.options.itemsLength = res.pages * 10;
					this.items = res.records;
				})
				.catch(error => {
					throw error;
				})
				.finally(() => {
					this.loading = false;
					this.firstFetch = true
				});
		},

		updateQuery(getQuery = false) {
			const query = Object.entries(this.filter).reduce((acc, [key, val]) => {
				if (!val) return acc;
				return { ...acc, [this.filtersQuery[key]]: val };
			}, {});

			if (getQuery === true) {
				return query;
			}

			this.$router.replace({ query: { ...query, page: this.options.page } });
			this.loadData(query);
		},
		// ? Конец методов таблицы
	},

	created() {
		this.$store.dispatch("ksk/load_houses");
	},

	async mounted() {
		try {
			const query = { ...this.$route.query };
			await this.loadData(query);
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style lang="scss" scoped>
.notifications-table {
	height: 100vh;
}
.disable-events {
  pointer-events: none
}
.no-uppercase {
	text-transform: unset !important;
}

.tooltip-card {
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
}

.highlighted-text {
  font-weight: 600;
  cursor: pointer;
}

.v-tooltip__content {
	padding: 0 !important;
}
</style>
